import { range } from './arrayHelpers'
import IconCropApple from 'assets/icons/IconApple'
import IconCropAvocado from 'assets/icons/IconAvocado'
import IconCropBanana from 'assets/icons/IconBanana'
import IconCropCitrus from 'assets/icons/IconCitrus'
import IconClementine from 'assets/icons/IconClementine'
import IconGrapefruit from 'assets/icons/IconGrapefruit'
import IconMandarin from 'assets/icons/IconMandarin'
import IconCropDates from 'assets/icons/IconDates'
import IconCropGrape from 'assets/icons/IconGrape'
import IconCropMango from 'assets/icons/IconMango'
import IconCropOlive from 'assets/icons/IconOlive'
import IconCropPomegranate from 'assets/icons/IconCropPomegranate'
import IconCropAlmonds from 'assets/icons/IconAlmond'
import IconCropLemon from 'assets/icons/IconLemon'
import IconCropPersimmon from 'assets/icons/IconCropPersimmon'
import IconCropWalnut from 'assets/icons/IconCropWalnut'
import IconPecan from 'assets/icons/IconPecan'
import IconMacadamia from 'assets/icons/IconMacadamia'
import IconSugarCane from 'assets/icons/IconSugarCane'
import IconPlum from 'assets/icons/IconPlum'
import IconPistachio from 'assets/icons/IconPistachio'
import IconCorn from 'assets/icons/IconCorn'
import IconNectarine from 'assets/icons/IconNectarine'
import IconPeach from 'assets/icons/IconPeach'
import IconPotato from 'assets/icons/IconPotato'
import IconWheat from 'assets/icons/IconWheat'
import PlantLogo from 'assets/images/icon_plant_dark_green.svg'
import AhernLogo from 'assets/images/icon_ahern_logo.svg'
import IconTomato from 'assets/icons/IconTomato'
import IconCoffee from 'assets/icons/IconCoffee'
import IconBlueberries from 'assets/icons/IconBlueberries'
import { PaymentOption, PlotCenter } from './types'

export enum Country {
  Israel = 'IL',
  Spain = 'ES',
  Mexico = 'MX',
  Morocco = 'MA',
  India = 'IN',
  Australia = 'AU',
  France = 'FR'
}

// when changing form steps , change their number and form name to be in correct order
export enum NewPlotFormSteps {
  CROP = 0,
  VARIETY = 1,
  // YEAR = 3,
  BLOSSOM = 2,
  // SOIL = 4,
  LOCATION = 3,
  CONTACT = 4,
  // UNIT = 5,
  // UNIT_VALUES = 6,
  // NAME = 5,
  // EXAMPLE = 5,
  // TIME = 6,
  OPTIONS = 5,
  PAYMENT = 6
}

export enum AddPlotFormSteps {
  CROP = 0,
  VARIETY = 1,
  // YEAR = 2,
  BLOSSOM = 2,
  // SOIL = 4,
  LOCATION = 3
  // UNIT_VALUES = 6,
  // NAME = 4
}

export const newPlotFormVariableNames = [
  'cropForm',
  'varietyForm',
  'cropBlossomForm',
  // 'plotYearForm',
  'plotLocationForm',
  // 'plotSoilForm',
  'contactForm',
  // 'plotUnitsForm',
  // 'plotUnitsValuesForm',
  // 'plotNameForm',
  // 'exampleForm',
  // 'plotDesiredTimeForm',
  'paymentOptionsForm',
  'paymentForm'
]

export const addPlotFormVariableNames = [
  'cropForm',
  'varietyForm',
  // 'plotYearForm',
  'cropBlossomForm',
  // 'plotSoilForm',
  'plotLocationForm'
  // 'plotUnitsValuesForm',
  // 'plotNameForm'
]

export interface PlotForm {
  cropForm: {
    cropType: string
    displayName: string
    cropId: number
    completed: boolean
  }
  varietyForm: {
    varietyType: string | number
    completed: boolean
  }
  // plotYearForm: {
  //   plantYear: number | undefined
  //   completed: boolean
  // }
  cropBlossomForm: {
    initialStageDate: Date | undefined
    completed: boolean
  }
  // plotUnitsForm: {
  //   selectedUnit: IrrigationUnit | undefined
  //   completed: boolean
  // }
  // plotUnitsValuesForm: {
  //   dripperCount: string | undefined
  //   rowDistance: string | undefined
  //   irrigationLines: string | undefined
  //   emitterDistance: string | undefined
  //   flowRate: string | undefined
  //   nominalFlow: number | undefined
  //   completed: boolean
  // }
  // plotSoilForm: {
  //   soilType: number | undefined
  //   growthMethod: string | undefined
  //   completed: boolean
  // }
  // plotNameForm: {
  //   plotName: string
  //   completed: boolean
  // }
  // exampleForm: {
  //   completed: boolean
  // }
  plotLocationForm: {
    plotBounds: any | undefined
    plotLocation: PlotCenter | undefined
    plotAreaAmount: number | null
    shouldDisplayPolygonConfirmation: boolean
    shouldDisplayPolygon: boolean
    completed: boolean
  }
  contactForm: {
    userName: string
    userNumber: string
    completed: boolean
    termsAccepted: boolean
  }
  // plotDesiredTimeForm: {
  //   userTime: string
  //   completed: boolean
  // }
  paymentOptionsForm: {
    paymentOption: PaymentOption | null
    completed: boolean
  }
  paymentForm: {
    payment: any
    paymentSuccessful: boolean
    completed: boolean
  }
}

export interface Crop {
  value: string
  name: string
  id: number
}

export interface Variety {
  value: string
  name: string
}

export const initialForm: PlotForm = {
  cropForm: {
    cropType: '',
    displayName: '',
    cropId: -1,
    completed: false
  },
  varietyForm: {
    varietyType: '',
    completed: false
  },
  // plotYearForm: {
  //   plantYear: undefined,
  //   completed: true
  // },
  cropBlossomForm: {
    initialStageDate: undefined,
    completed: false
  },
  // plotUnitsForm: {
  //   selectedUnit: undefined,
  //   completed: false
  // },
  // plotUnitsValuesForm: {
  //   dripperCount: undefined,
  //   rowDistance: undefined,
  //   irrigationLines: undefined,
  //   emitterDistance: undefined,
  //   flowRate: undefined,
  //   nominalFlow: undefined,
  //   completed: false
  // },
  // plotSoilForm: {
  //   soilType: 2,
  //   growthMethod: undefined,
  //   completed: true
  // },
  // plotNameForm: {
  //   plotName: '',
  //   completed: false
  // },
  // exampleForm: {
  //   completed: false
  // },
  plotLocationForm: {
    plotLocation: undefined,
    plotBounds: undefined,
    plotAreaAmount: null,
    shouldDisplayPolygonConfirmation: false,
    shouldDisplayPolygon: true,
    completed: false
  },
  contactForm: {
    userName: '',
    userNumber: '',
    completed: false,
    termsAccepted: false
  },
  // plotDesiredTimeForm: {
  //   userTime: '',
  //   completed: false
  // },
  paymentOptionsForm: {
    paymentOption: null,
    completed: false
  },
  paymentForm: {
    payment: {},
    paymentSuccessful: false,
    completed: false
  }
}

export const getCropIcon = (crop: string) => {
  switch (crop) {
    case 'Avocado':
      return IconCropAvocado
    case 'Orange':
      return IconCropCitrus
    case 'Clementine':
      return IconClementine
    case 'Mandarin':
      return IconMandarin
    case 'Grapefruit':
      return IconGrapefruit
    case 'Mango':
      return IconCropMango
    case 'Apple':
      return IconCropApple
    case 'Grape table':
    case 'Grape wine':
      return IconCropGrape
    case 'Dates':
      return IconCropDates
    case 'Banana':
      return IconCropBanana
    case 'Olive':
      return IconCropOlive
    case 'Pomegranate':
      return IconCropPomegranate
    case 'Almond':
      return IconCropAlmonds
    case 'Lemon':
      return IconCropLemon
    case 'Persimmon':
      return IconCropPersimmon
    case 'Walnut':
      return IconCropWalnut
    case 'Pecan':
      return IconPecan
    case 'Macadamia':
      return IconMacadamia
    case 'Sugar Cane':
      return IconSugarCane
    case 'Corn':
      return IconCorn
    case 'Plum':
      return IconPlum
    case 'Pistachio':
      return IconPistachio
    case 'Nectarine':
      return IconNectarine
    case 'Peach':
      return IconPeach
    case 'Potato':
      return IconPotato
    case 'Wheat':
      return IconWheat
    case 'Tomato':
      return IconTomato
    case 'Coffee':
      return IconCoffee
    case 'Blueberry':
      return IconBlueberries
    default:
      return undefined
  }
}

export enum Steps {
  LandingPage = 0,
  Form = 1,
  SavedMoney = 2,
  Ending = 3,
  OtherCrop = 4
}

export const getDunamArray = (start: number, end: number, step: number) => {
  return range(start, end, step)
}

export const getTimeStringArray = (start: number, end: number) => {
  const timeValueArray = range(start, end)
  const formattedTimeValueArray = timeValueArray.map((value) => {
    if (value >= 10) {
      return `${value}:00`
    }

    return `0${value}:00`
  })

  return formattedTimeValueArray
}

export const config = {
  fruits: []
}

export enum PaymentMethods {
  ECommerce = 'ECOMMERCE',
  Coupon = 'COUPON',
  MasterPool = 'MASTER_POOL',
  None = 'NONE',
  ServiceContract = 'SERVICE_CONTRACT'
}

export enum Subdomain {
  App = 'app',
  Ahern = 'ahern',
  Carrefour = 'carrefour',
  Mexico = 'mexico'
}

export const getSubdomain = (): Subdomain => {
  return window.location.origin
    .split('.')?.[0]
    ?.replace(/(https?:\/\/)?(www.)?/i, '')
    ?.toLowerCase() as Subdomain
}

export const getPaymentMethodFromSubdomain = () => {
  const subdomain = getSubdomain()
  switch (subdomain) {
    case Subdomain.App:
      return PaymentMethods.ECommerce
    case Subdomain.Ahern:
      return PaymentMethods.MasterPool
    case Subdomain.Carrefour:
      return PaymentMethods.Coupon
    default:
      return PaymentMethods.ECommerce
  }
}

export const getCompanyLogoFromSubdomain = () => {
  const subdomain = getSubdomain()
  switch (subdomain) {
    case Subdomain.App:
      return PlantLogo
    case Subdomain.Ahern:
      return AhernLogo
    case Subdomain.Carrefour:
      return ''
    default:
      return PlantLogo
  }
}

export const checkIsMasterPoolOrCouponPayment = (): boolean => {
  const paymentMethod = getPaymentMethodFromSubdomain()
  return (
    paymentMethod === PaymentMethods.MasterPool ||
    paymentMethod === PaymentMethods.Coupon
  )
}
